// src/pages/Dashboard.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function processDataForDashboard(answersArray) {
  const grouped = {};

  answersArray.forEach((ans) => {
    const qId = ans.question_id;
    if (!grouped[qId]) {
      grouped[qId] = {
        questionId: qId,
        questionText: ans.question.text,
        questionType: ans.question.type,
        questionOptions: ans.question.options || [],
        openResponses: [],
      };
    }
    grouped[qId].openResponses.push(ans.response_text);
  });

  return Object.values(grouped).map((group) => {
    const { questionId, questionText, questionType, questionOptions, openResponses } = group;

    const dataObject = {
      questionId,
      questionText,
      questionType,
      chartData: [],
      openResponses: [],
    };

    if (questionType === "open") {
      dataObject.openResponses = openResponses;
    } else {
      switch (questionType) {
        case "satisfaction":
          const countsSat = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };
          openResponses.forEach((val) => countsSat[val]++);
          dataObject.chartData = Object.entries(countsSat).map(([key, count]) => ({
            name: `Carita ${key}`,
            value: count,
          }));
          break;

        case "multiple":
        case "unique":
          const countsMulti = {};
          questionOptions.forEach((opt) => (countsMulti[opt] = 0));
          openResponses.forEach((val) => {
            if (questionType === "multiple") {
              val.split(",").forEach((choice) => countsMulti[choice.trim()]++);
            } else {
              countsMulti[val]++;
            }
          });
          dataObject.chartData = Object.entries(countsMulti).map(([opt, count]) => ({
            name: opt,
            value: count,
          }));
          break;

        case "scale":
          const countsScale = {};
          openResponses.forEach((val) => (countsScale[val] = (countsScale[val] || 0) + 1));
          dataObject.chartData = Object.entries(countsScale).map(([opt, count]) => ({
            name: `Puntaje ${opt}`,
            value: count,
          }));
          break;

        default:
          dataObject.chartData = [{ name: "Respuestas", value: openResponses.length }];
          break;
      }
    }

    return dataObject;
  });
}

function Dashboard() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const storedBrandId = localStorage.getItem("brandId");

  const [locationsData, setLocationsData] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [answers, setAnswers] = useState([]);
  const [processedQuestions, setProcessedQuestions] = useState([]);

  useEffect(() => {
    if (!token || !role) {
      navigate("/login");
      return;
    }

    if ((role === "administrador" || role === "vendedor") && storedBrandId) {
      fetchLocations(storedBrandId);
    }
  }, [token, role, navigate, storedBrandId]);

  const fetchLocations = async (brandId) => {
    try {
      const resp = await api.get(`/dashboard/brand/${brandId}/locations`);
      setLocationsData(resp.data);
    } catch (err) {
      console.error("Error al obtener ubicaciones:", err);
      alert("Error al obtener ubicaciones");
    }
  };

  const fetchResponses = async () => {
    if (!storedBrandId) {
      alert("No se ha seleccionado ninguna marca");
      return;
    }

    try {
      const url = `/dashboard/brand/${storedBrandId}/responses${
        selectedLocationId ? `?location_id=${selectedLocationId}` : ""
      }`;
      const resp = await api.get(url);
      setAnswers(resp.data.answers || []);
    } catch (error) {
      console.error("Error al obtener respuestas:", error);
      alert("Error al obtener respuestas");
    }
  };

  useEffect(() => {
    if (answers.length > 0) {
      const finalData = processDataForDashboard(answers);
      setProcessedQuestions(finalData);
    } else {
      setProcessedQuestions([]);
    }
  }, [answers]);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-md shadow-md p-6">
          <h2 className="text-2xl font-bold mb-6">Dashboard de Resultados</h2>

          {locationsData && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">
                Marca: {locationsData.brand_name}
              </h3>
              <div className="flex gap-4 items-end">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccione una sucursal
                  </label>
                  <select
                    value={selectedLocationId}
                    onChange={(e) => setSelectedLocationId(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Todas las sucursales</option>
                    {locationsData.locations.map((loc) => (
                      <option key={loc.id} value={loc.id}>
                        {loc.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={fetchResponses}
                  className="px-4 py-2 bg-[#FF3008] text-white rounded-md hover:bg-[#FF3008]/90 transition-colors"
                >
                  Ver Respuestas
                </button>
              </div>
            </div>
          )}

          {answers.length > 0 ? (
            <div className="space-y-8">
              <h3 className="text-xl font-semibold">
                Resultados de Encuestas (Total: {answers.length})
              </h3>

              {processedQuestions.map((q) => {
                if (q.questionType === "open") {
                  return (
                    <div key={q.questionId} className="p-4 border rounded-md bg-gray-50">
                      <h4 className="text-lg font-semibold mb-2">
                        {q.questionText} (Respuestas Abiertas)
                      </h4>
                      {q.openResponses.length > 0 ? (
                        <ul className="list-disc list-inside space-y-1">
                          {q.openResponses.map((resp, idx) => (
                            <li key={idx} className="text-gray-700">
                              {resp || "(vacío)"}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-gray-500">Sin respuestas</p>
                      )}
                    </div>
                  );
                }

                return (
                  <div key={q.questionId} style={{ height: 300, marginBottom: "2rem" }}>
                    <h4 className="text-lg font-semibold mb-2">
                      {q.questionText} (Tipo: {q.questionType})
                    </h4>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={q.chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#FF3008" name="Cantidad" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-gray-500">
              {locationsData 
                ? "Seleccione una sucursal y haga clic en 'Ver Respuestas'"
                : "Cargando información de la marca..."}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;