


//src/pages/Locations.jsx

import React, { useEffect, useState } from "react";
import api from "../services/api";

function Locations() {
  const [clientId, setClientId] = useState("");
  const [locations, setLocations] = useState([]);
  const [locName, setLocName] = useState("");
  const [locAddress, setLocAddress] = useState("");

  const fetchLocations = async () => {
    if (!clientId) return;
    try {
      const response = await api.get(`/locations/clients/${clientId}/locations`);
      setLocations(response.data);
    } catch (error) {
      console.error("Error al traer ubicaciones:", error);
      alert("No se pudieron obtener las ubicaciones.");
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchLocations();
    }
  }, [clientId]);

  const handleAddLocation = async (e) => {
    e.preventDefault();
    if (!clientId) {
      alert("Debes especificar el Client ID");
      return;
    }
    try {
      const response = await api.post(`/locations/clients/${clientId}/locations`, {
        name: locName,
        address: locAddress,
      });
      console.log("Ubicación añadida:", response.data);
      alert("Ubicación añadida con éxito");
      setLocName("");
      setLocAddress("");
      fetchLocations();
    } catch (error) {
      console.error("Error al añadir ubicación:", error);
      alert("Error al añadir ubicación");
    }
  };

  return (
    <div className="min-h-screen pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-2xl font-bold mb-6">Gestión de Ubicaciones</h2>

          <div className="card mb-8">
            <div className="flex gap-4 items-end mb-6">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Client ID
                </label>
                <input
                  type="number"
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                  placeholder="Ej: 1"
                  className="input-standard"
                />
              </div>
              <button onClick={fetchLocations} className="btn-primary">
                Buscar Ubicaciones
              </button>
            </div>

            <form onSubmit={handleAddLocation} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nombre de la ubicación
                </label>
                <input
                  type="text"
                  value={locName}
                  onChange={(e) => setLocName(e.target.value)}
                  className="input-standard"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Dirección
                </label>
                <input
                  type="text"
                  value={locAddress}
                  onChange={(e) => setLocAddress(e.target.value)}
                  className="input-standard"
                />
              </div>

              <button type="submit" className="btn-primary">
                Añadir Ubicación
              </button>
            </form>
          </div>

          <div className="card">
            <h3 className="text-xl font-semibold mb-4">
              Ubicaciones del Cliente {clientId}
            </h3>

            {locations.length > 0 ? (
              <div className="divide-y">
                {locations.map((loc) => (
                  <div key={loc.id} className="py-4">
                    <div className="font-medium">{loc.name}</div>
                    <div className="text-gray-600 text-sm mt-1">
                      {loc.address}
                    </div>
                    <div className="text-sm text-gray-500 mt-1">
                      URL: {loc.unique_url || "No generada (Plan Demo)"}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500">No hay ubicaciones.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locations;

