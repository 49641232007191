
//src/pages/Questions.jsx
import React, { useState, useEffect } from "react";
import api from "../services/api";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

function Questions() {
  const [questions, setQuestions] = useState([]);
  const [text, setText] = useState("");
  const [type, setType] = useState("satisfaction");
  const [options, setOptions] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [isShared, setIsShared] = useState(true);
  const [editingQuestion, setEditingQuestion] = useState(null);

  useEffect(() => { fetchQuestions(); }, []);

  const fetchQuestions = async () => {
    try {
      const response = await api.get("/questions/");
      setQuestions(response.data);
    } catch (error) { console.error("Error al obtener preguntas:", error); }
  };

  const handleCreateQuestion = async (e) => {
    e.preventDefault();
    try {
      const optionsArray = options.split(",").map(opt => opt.trim()).filter(o => o);
      const payload = { text, type, is_shared: isShared, options: ["multiple", "unique"].includes(type) ? optionsArray : null, min_value: type === "scale" ? parseInt(minValue) : null, max_value: type === "scale" ? parseInt(maxValue) : null };
      await api.post("/questions/", payload);
      fetchQuestions();
      resetForm();
    } catch (error) { console.error("Error al crear pregunta:", error); }
  };

  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
    setText(question.text);
    setType(question.type);
    setOptions(question.options?.join(", ") || "");
    setMinValue(question.min_value?.toString() || "");
    setMaxValue(question.max_value?.toString() || "");
    setIsShared(question.is_shared);
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    try {
      const optionsArray = options.split(",").map(opt => opt.trim()).filter(o => o);
      const payload = { text, type, is_shared: isShared, options: ["multiple", "unique"].includes(type) ? optionsArray : null, min_value: type === "scale" ? parseInt(minValue) : null, max_value: type === "scale" ? parseInt(maxValue) : null };
      await api.put(`/questions/${editingQuestion.id}`, payload);
      fetchQuestions();
      resetForm();
    } catch (error) { console.error("Error actualizando pregunta:", error); }
  };

  const handleDeleteQuestion = async (questionId) => {
    if (window.confirm("¿Estás seguro de eliminar esta pregunta? Esta acción es irreversible.")) {
      try {
        await api.delete(`/questions/${questionId}`);
        fetchQuestions();
        alert("Pregunta eliminada correctamente");
      } catch (error) { alert(error.response?.data?.detail || "Error al eliminar la pregunta"); }
    }
  };

  const resetForm = () => {
    setEditingQuestion(null);
    setText("");
    setType("satisfaction");
    setOptions("");
    setMinValue("");
    setMaxValue("");
    setIsShared(true);
  };

  return (
    <div className="min-h-screen pt-16 px-4 bg-gray-50">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Administración de Preguntas</h2>

        <form onSubmit={editingQuestion ? handleUpdateQuestion : handleCreateQuestion} className="space-y-4">
          <input type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder="¿Qué te pareció nuestro servicio?" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FF3008]" required />
          
          <select value={type} onChange={(e) => setType(e.target.value)} className="w-full p-2 border border-gray-300 rounded-md bg-white">
            <option value="satisfaction">Satisfacción (Emojis)</option>
            <option value="multiple">Múltiple Opción</option>
            <option value="unique">Opción Única</option>
            <option value="open">Respuesta Abierta</option>
            <option value="scale">Escala Numérica</option>
          </select>

          {(type === "multiple" || type === "unique") && (
            <div>
              <input type="text" value={options} onChange={(e) => setOptions(e.target.value)} placeholder="Ej: Excelente, Bueno, Regular, Malo" className="w-full p-2 border border-gray-300 rounded-md" />
              <p className="text-sm text-gray-500 mt-1">Separar opciones con comas</p>
            </div>
          )}

          {type === "scale" && (
            <div className="grid grid-cols-2 gap-4">
              <input type="number" value={minValue} onChange={(e) => setMinValue(e.target.value)} placeholder="Valor mínimo" className="w-full p-2 border border-gray-300 rounded-md" />
              <input type="number" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} placeholder="Valor máximo" className="w-full p-2 border border-gray-300 rounded-md" />
            </div>
          )}

          <div className="flex items-center gap-2">
            <input type="checkbox" checked={isShared} onChange={(e) => setIsShared(e.target.checked)} className="h-4 w-4 text-[#FF3008] rounded border-gray-300" />
            <span className="text-sm text-gray-600">Pregunta reusable para otros clientes</span>
          </div>

          <button type="submit" className="w-full py-2 px-4 bg-[#FF3008] text-white rounded-md hover:bg-[#E62B07] transition-colors">
            {editingQuestion ? "Actualizar Pregunta" : "Crear Nueva Pregunta"}
          </button>
        </form>

        <hr className="my-6 border-gray-200" />

        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-800">Preguntas Existentes</h3>
          {questions.map((question) => (
            <div key={question.id} className="border border-gray-200 rounded-md p-4 bg-gray-50 group hover:bg-white transition-colors">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <h4 className="font-medium text-gray-900">{question.text}</h4>
                  <div className="mt-2 text-sm text-gray-600">
                    <span className="bg-[#FF3008]/10 text-[#FF3008] px-2 py-1 rounded-md">{question.type.toUpperCase()}</span>
                    {question.options && <span className="ml-2">Opciones: {question.options.join(", ")}</span>}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <button onClick={() => handleEditQuestion(question)} className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-gray-100 rounded-md">
                    <PencilIcon className="w-5 h-5 text-[#FF3008]" />
                  </button>
                  <button onClick={() => handleDeleteQuestion(question.id)} className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-gray-100 rounded-md text-red-600">
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Questions;