// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* para que se vea por encima de todo */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 1rem;
    max-width: 500px;
    width: 90%;
    border-radius: 8px;
    z-index: 10000;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Modal.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,uCAAuC;EACxD;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":["/* src/pages/Modal.css */\r\n.modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.4);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 9999; /* para que se vea por encima de todo */\r\n  }\r\n  \r\n  .modal-content {\r\n    background-color: #fff;\r\n    padding: 1rem;\r\n    max-width: 500px;\r\n    width: 90%;\r\n    border-radius: 8px;\r\n    z-index: 10000;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
