// src/pages/ClientSelection.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function ClientSelection() {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await api.get('/clients/');
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
                alert('Error al cargar clientes');
            }
        };
        fetchClients();
    }, []);

    const handleNavigate = () => {
        if (!selectedClient) {
            alert('Selecciona un cliente');
            return;
        }
        localStorage.setItem('brandId', selectedClient);
        navigate('/dashboard');
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                <h2 className="text-2xl font-bold mb-6 text-center">Selección de Cliente</h2>
                <div className="space-y-4">
                    <select
                        value={selectedClient}
                        onChange={(e) => setSelectedClient(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="">Selecciona un cliente</option>
                        {clients.map(client => (
                            <option key={client.id} value={client.id}>
                                {client.brand_name} - {client.email}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleNavigate}
                        className="w-full bg-[#FF3008] text-white py-2 px-4 rounded-md hover:bg-[#FF3008]/90"
                    >
                        Continuar al Dashboard
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ClientSelection;