// src/pages/RegisterClient.jsx

import React, { useState, useEffect } from "react";
import api from "../services/api";
import { jsPDF } from "jspdf";
import "./Modal.css";
import "../styles.css";

function RegisterClient() {
  // Estados
  const [vendorId, setVendorId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [adminPassword, setAdminPassword] = useState("");
  const [locations, setLocations] = useState([{ name: "", address: "" }]);
  const [questions, setQuestions] = useState([
    {
      text: "",
      type: "satisfaction",
      options: "",
      min_value: "",
      max_value: "",
      is_shared: true,
    },
  ]);
  const [questionBank, setQuestionBank] = useState([]);
  const [selectedExistingQuestions, setSelectedExistingQuestions] = useState([]);
  const [registeredLocations, setRegisteredLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [uniqueUrl, setUniqueUrl] = useState("");

  // Tipo de plan
  const [planType, setPlanType] = useState("free");

  // Spinners
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  // Modals
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    fetchQuestionBank();
  }, []);

  const fetchQuestionBank = async () => {
    try {
      const response = await api.get("/questions/");
      setQuestionBank(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddLocation = () => {
    setLocations([...locations, { name: "", address: "" }]);
  };

  const handleLocationChange = (index, field, value) => {
    const newLocs = [...locations];
    newLocs[index][field] = value;
    setLocations(newLocs);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        type: "satisfaction",
        options: "",
        min_value: "",
        max_value: "",
        is_shared: true,
      },
    ]);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQs = [...questions];
    newQs[index][field] = value;
    setQuestions(newQs);
  };

  const handleToggleExistingQuestion = (questionId) => {
    setSelectedExistingQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((id) => id !== questionId);
      } else {
        return [...prev, questionId];
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadingLogo(true);
      setTimeout(() => {
        setLogoFile(file);
        setIsUploadingLogo(false);
      }, 800);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!logoFile) {
      alert("Debes adjuntar un archivo de imagen para el logo.");
      return;
    }
    setIsRegistering(true);

    try {
      const formData = new FormData();
      formData.append("file", logoFile);
      formData.append("brand_name", brandName);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("admin_password", adminPassword);
      formData.append("plan_type", planType);

      if (vendorId.trim() !== "") {
        formData.append("vendor_id", vendorId);
      }

      // Preguntas nuevas
      const newQuestionsPayload = questions.map((q) => ({
        text: q.text,
        type: q.type,
        options: q.options
          ? q.options.split(",").map((opt) => opt.trim()).filter((o) => o)
          : null,
        min_value: q.min_value ? parseInt(q.min_value) : null,
        max_value: q.max_value ? parseInt(q.max_value) : null,
        is_shared: q.is_shared,
      }));

      // Preguntas existentes
      const existingQsSelected = questionBank.filter((q) =>
        selectedExistingQuestions.includes(q.id)
      );
      const payloadExistingQuestions = existingQsSelected.map((exQ) => ({
        text: exQ.text,
        type: exQ.type,
        options: exQ.options,
        min_value: exQ.min_value,
        max_value: exQ.max_value,
        is_shared: exQ.is_shared,
      }));

      const combinedAllQuestions = [
        ...newQuestionsPayload,
        ...payloadExistingQuestions,
      ];
      formData.append("questions", JSON.stringify(combinedAllQuestions));

      // Registrar cliente
      const resClient = await api.post("/clients/register-client", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const clientId = resClient.data.id;

      // Registrar sucursales
      const locResponses = [];
      for (const loc of locations) {
        if (loc.name.trim() && loc.address.trim()) {
          const resLoc = await api.post(
            `/locations/clients/${clientId}/locations`,
            {
              name: loc.name,
              address: loc.address,
            }
          );
          locResponses.push(resLoc.data);
        }
      }
      setRegisteredLocations(locResponses);

      // Limpiar
      setVendorId("");
      setBrandName("");
      setName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setLogoFile(null);
      setAdminPassword("");
      setLocations([{ name: "", address: "" }]);
      setQuestions([
        {
          text: "",
          type: "satisfaction",
          options: "",
          min_value: "",
          max_value: "",
          is_shared: true,
        },
      ]);
      setSelectedExistingQuestions([]);

      // Mostrar modal de QR + éxito
      setShowModal(true);
      setTimeout(() => {
        setShowSuccessModal(true);
      }, 600);
    } catch (err) {
      console.error(err);
      alert("Error al registrar cliente (ver consola).");
    } finally {
      setIsRegistering(false);
    }
  };

  const handleGenerateQR = async () => {
    if (!selectedLocationId) {
      alert("Selecciona una sucursal para generar el QR.");
      return;
    }
    try {
      const resp = await api.get(`/locations/${selectedLocationId}/generate-qr`);
      setQrCodeBase64(resp.data.qr_code_base64);
      setUniqueUrl(resp.data.unique_url);
      alert("QR Code generado.");
    } catch (error) {
      console.error(error);
      alert("Error al generar QR (ver consola).");
    }
  };

  const handleDownloadSelect = (e) => {
    const format = e.target.value;
    if (!format) return;
    if (!qrCodeBase64) {
      alert("No se ha generado aún el código QR.");
      e.target.value = "";
      return;
    }
    const base64Data = `data:image/png;base64,${qrCodeBase64}`;
    if (format === "jpg") {
      const link = document.createElement("a");
      link.href = base64Data;
      link.download = "qr_code.jpg";
      link.click();
    } else if (format === "pdf") {
      const doc = new jsPDF();
      doc.addImage(base64Data, "PNG", 10, 10, 50, 50);
      doc.save("qr_code.pdf");
    }
    e.target.value = "";
  };

  const closeModal = () => {
    setShowModal(false);
    setQrCodeBase64("");
    setUniqueUrl("");
    setSelectedLocationId("");
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="w-full min-h-screen flex flex-col bg-white">
      {/* 
         QUITAMOS <header> y <footer> para no duplicar el que ya está en App.js 
         Solo dejamos el contenido principal
      */}

      <div className="max-w-5xl mx-auto mt-8 px-4 py-6 sm:px-6 lg:px-8 bg-gray-50 shadow-md rounded-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Registrar Cliente (Marca)
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* VENDOR ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Vendor ID (opcional)
            </label>
            <input
              type="number"
              value={vendorId}
              onChange={(e) => setVendorId(e.target.value)}
              placeholder="Si se deja vacío, no se asocia a ningún vendedor"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Nombre de Marca */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nombre de Marca
            </label>
            <input
              type="text"
              required
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Nombre Empresa */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nombre de la Empresa
            </label>
            <input
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Correo */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Correo
            </label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Teléfono */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Teléfono
            </label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Dirección */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Dirección (principal)
            </label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Logo (archivo) */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Logo (imagen)
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600 justify-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-[#FF3008] hover:text-[#FF3008]/90 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-[#FF3008]"
                  >
                    <span>Subir un archivo</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      onChange={handleFileChange}
                      required
                    />
                  </label>
                  <p className="pl-1">o arrastrar y soltar</p>
                  {isUploadingLogo && <div className="spinner"></div>}
                </div>
                <p className="text-xs text-gray-500">PNG, JPG hasta 10MB</p>
                {logoFile && !isUploadingLogo && (
                  <p className="text-xs text-green-600">
                    Archivo listo: {logoFile.name}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Contraseña Admin */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contraseña del Administrador
            </label>
            <input
              type="password"
              required
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
            />
          </div>

          {/* Tipo de Plan */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tipo de Plan
            </label>
            <div className="relative">
              <select
                value={planType}
                onChange={(e) => setPlanType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
              >
                <option value="free">Plan Free</option>
                <option value="premium">Plan Premium</option>
              </select>
              <div className="tooltip-container text-sm text-gray-500 absolute top-2 right-2">
                ℹ️
                <div className="tooltip-text text-xs">
                  <p>
                    <strong>Plan Free:</strong> Limitado a 3 sucursales (URL).
                  </p>
                  <p>
                    <strong>Plan Premium:</strong> Sucursales ilimitadas,
                    análisis avanzado.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Sucursales */}
          <div className="pt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Sucursales</h3>
            <div className="space-y-4">
              {locations.map((loc, index) => (
                <div
                  key={index}
                  className="p-4 bg-gray-100 rounded-lg border border-gray-200"
                >
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Nombre Sucursal
                      </label>
                      <input
                        type="text"
                        value={loc.name}
                        onChange={(e) =>
                          handleLocationChange(index, "name", e.target.value)
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Dirección Sucursal
                      </label>
                      <input
                        type="text"
                        value={loc.address}
                        onChange={(e) =>
                          handleLocationChange(index, "address", e.target.value)
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddLocation}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#FF3008] bg-[#FF3008]/10 hover:bg-[#FF3008]/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF3008]"
              >
                + Agregar Sucursal
              </button>
            </div>
          </div>

          {/* Preguntas Personalizadas */}
          <div className="pt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Preguntas Personalizadas
            </h3>
            <div className="space-y-4">
              {questions.map((q, i) => (
                <div
                  key={i}
                  className="p-4 bg-gray-100 rounded-lg border border-gray-200"
                >
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Texto de la pregunta
                      </label>
                      <input
                        type="text"
                        value={q.text}
                        onChange={(e) => handleQuestionChange(i, "text", e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Tipo de pregunta
                      </label>
                      <select
                        value={q.type}
                        onChange={(e) => handleQuestionChange(i, "type", e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      >
                        <option value="satisfaction">
                          Satisfacción (Caritas)
                        </option>
                        <option value="multiple">Múltiple</option>
                        <option value="unique">Única</option>
                        <option value="open">Respuesta Abierta</option>
                        <option value="scale">Escala Personalizada</option>
                      </select>
                    </div>
                    {(q.type === "multiple" || q.type === "unique") && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Opciones (separadas por coma)
                        </label>
                        <input
                          type="text"
                          value={q.options}
                          onChange={(e) =>
                            handleQuestionChange(i, "options", e.target.value)
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                        />
                      </div>
                    )}
                    {q.type === "scale" && (
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Valor mínimo
                          </label>
                          <input
                            type="number"
                            value={q.min_value}
                            onChange={(e) =>
                              handleQuestionChange(i, "min_value", e.target.value)
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Valor máximo
                          </label>
                          <input
                            type="number"
                            value={q.max_value}
                            onChange={(e) =>
                              handleQuestionChange(i, "max_value", e.target.value)
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={q.is_shared}
                        onChange={(e) =>
                          handleQuestionChange(i, "is_shared", e.target.checked)
                        }
                        className="h-4 w-4 text-[#FF3008] focus:ring-[#FF3008] border-gray-300 rounded"
                      />
                      <label className="ml-2 block text-sm text-gray-700">
                        ¿Compartida (is_shared)?
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddQuestion}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#FF3008] bg-[#FF3008]/10 hover:bg-[#FF3008]/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF3008]"
              >
                + Agregar Pregunta
              </button>
            </div>
          </div>

          {/* Preguntas Existentes */}
          <div className="pt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Preguntas Existentes
            </h3>
            <div className="bg-gray-100 rounded-lg border border-gray-200 p-4">
              {questionBank.length === 0 ? (
                <p className="text-sm text-gray-500">
                  No hay preguntas existentes.
                </p>
              ) : (
                <div className="space-y-2">
                  {questionBank.map((qb) => (
                    <div key={qb.id} className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          type="checkbox"
                          checked={selectedExistingQuestions.includes(qb.id)}
                          onChange={() => handleToggleExistingQuestion(qb.id)}
                          className="h-4 w-4 text-[#FF3008] focus:ring-[#FF3008] border-gray-300 rounded"
                        />
                      </div>
                      <label className="ml-3 text-sm text-gray-700">
                        #{qb.id} - ({qb.type}) {qb.text}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Botón Registrar */}
          <div className="pt-6">
            <button
              type="submit"
              className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#FF3008] hover:bg-[#FF3008]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF3008]"
              disabled={isRegistering}
            >
              {isRegistering ? (
                <>
                  Registrando...
                  <div className="spinner"></div>
                </>
              ) : (
                "Registrar Cliente + Sucursales"
              )}
            </button>
          </div>
        </form>
      </div>

      {/* MODAL QR */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6 relative">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Generar QR de Sucursales
            </h2>
            {registeredLocations.length === 0 ? (
              <p className="text-sm text-gray-500">
                No hay sucursales registradas.
              </p>
            ) : (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Sucursal
                  </label>
                  <select
                    value={selectedLocationId}
                    onChange={(e) => setSelectedLocationId(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                  >
                    <option value="">-- Seleccionar --</option>
                    {registeredLocations.map((loc) => (
                      <option key={loc.id} value={loc.id}>
                        {loc.name} (ID: {loc.id})
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={handleGenerateQR}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#FF3008] hover:bg-[#FF3008]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF3008]"
                >
                  Generar QR
                </button>
                {qrCodeBase64 && (
                  <div className="mt-4 space-y-4">
                    <div className="flex justify-center">
                      <img
                        src={`data:image/png;base64,${qrCodeBase64}`}
                        alt="QR Code"
                        className="w-40 h-40"
                      />
                    </div>
                    {uniqueUrl && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">URL:</span>{" "}
                        <a
                          href={uniqueUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[#FF3008] hover:text-[#FF3008]/90"
                        >
                          {uniqueUrl}
                        </a>
                      </p>
                    )}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Descargar como
                      </label>
                      <select
                        onChange={handleDownloadSelect}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      >
                        <option value="">-- Selecciona formato --</option>
                        <option value="jpg">JPG</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* MODAL de Éxito Final */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <h2 className="text-xl font-bold text-green-600 mb-4">¡Éxito!</h2>
            <p className="text-gray-700 mb-4">
              El cliente y sus sucursales se han registrado correctamente.
            </p>
            <button
              onClick={closeSuccessModal}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterClient;
