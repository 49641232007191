// src/pages/PublicSurvey.jsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import logo from "../images/logo.png";

function PublicSurvey() {
  const { brandSlug, locationSlug } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await api.get(`/surveys/${brandSlug}/${locationSlug}`);
        const { brand_name, logo_url, location_name, questions } = response.data;
        
        setBrandName(brand_name);
        setLogoUrl(logo_url);
        setLocationName(location_name);
        setQuestions(questions);
        
        // Inicializar respuestas
        const initialAnswers = questions.map(q => ({
          question_id: q.id,
          response_text: ""
        }));
        setAnswers(initialAnswers);
      } catch (error) {
        console.error("Error cargando encuesta:", error);
      }
    };
    
    fetchSurvey();
  }, [brandSlug, locationSlug]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers(prev => prev.map(answer => 
      answer.question_id === questionId 
        ? { ...answer, response_text: value } 
        : answer
    ));
  };

  const handleSubmit = async () => {
    try {
      await api.post(`/surveys/${brandSlug}/${locationSlug}`, { answers });
      alert("¡Gracias por tu opinión!");
      window.location.reload();
    } catch (error) {
      console.error("Error enviando respuestas:", error);
      alert("Ocurrió un error al enviar tus respuestas");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-[#FF3008] py-4 px-8 flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <img src={logo} alt="Logo" className="h-8 w-auto" />
          <span className="text-white font-bold">Hidden Shopper</span>
        </div>
      </header>

      <main className="max-w-2xl mx-auto p-4">
        <div className="bg-white rounded-xl shadow-lg p-6 mt-8">
          <div className="text-center mb-8">
            {logoUrl && (
              <img src={logoUrl} alt="Logo Marca" className="w-32 h-32 mx-auto mb-4 object-contain" />
            )}
            <h1 className="text-2xl font-bold text-gray-900">{brandName}</h1>
            <p className="text-gray-600 mt-2">{locationName}</p>
          </div>

          <div className="space-y-6">
            {questions.map((question) => {
              const currentAnswer = answers.find(a => a.question_id === question.id)?.response_text || "";

              return (
                <div key={question.id} className="border-b border-gray-200 pb-6 last:border-0">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">{question.text}</h3>
                  
                  {question.type === "open" && (
                    <textarea
                      value={currentAnswer}
                      onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md h-24"
                      placeholder="Escribe tu respuesta aquí..."
                    />
                  )}

                  {question.type === "multiple" && (
                    <div className="grid grid-cols-1 gap-2">
                      {question.options.map((option) => (
                        <label key={option} className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            checked={currentAnswer.includes(option)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...currentAnswer.split(","), option].filter(Boolean).join(",")
                                : currentAnswer.split(",").filter(item => item !== option).join(",");
                              handleAnswerChange(question.id, newValue);
                            }}
                            className="rounded border-gray-300 text-[#FF3008]"
                          />
                          <span>{option}</span>
                        </label>
                      ))}
                    </div>
                  )}

                  {question.type === "unique" && (
                    <div className="grid grid-cols-1 gap-2">
                      {question.options.map((option) => (
                        <label key={option} className="flex items-center space-x-2">
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={currentAnswer === option}
                            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                            className="rounded-full border-gray-300 text-[#FF3008]"
                          />
                          <span>{option}</span>
                        </label>
                      ))}
                    </div>
                  )}

                  {question.type === "satisfaction" && (
                    <div className="flex flex-col items-center py-4">
                      <div className="flex justify-between w-full text-sm text-gray-600 mb-2">
                        <span>Muy Insatisfecho</span>
                        <span>Muy Satisfecho</span>
                      </div>
                      <div className="flex gap-4">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <label
                            key={value}
                            className="flex flex-col items-center cursor-pointer"
                          >
                            <input
                              type="radio"
                              name={`satisfaction_${question.id}`}
                              value={value}
                              checked={currentAnswer === value.toString()}
                              onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                              className="hidden"
                            />
                            <div
                              className={`w-12 h-12 rounded-full flex items-center justify-center text-2xl transition-all ${
                                currentAnswer === value.toString()
                                  ? "bg-[#FF3008] text-white scale-110"
                                  : "bg-gray-100 hover:bg-gray-200"
                              }`}
                            >
                              {["😠", "😞", "😐", "😊", "😍"][value - 1]}
                            </div>
                            <span className="text-xs text-gray-600 mt-1">{value}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}

                  {question.type === "scale" && (
                    <div className="space-y-4">
                      <input
                        type="range"
                        min={question.min_value}
                        max={question.max_value}
                        value={currentAnswer || question.min_value}
                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                        className="w-full"
                      />
                      <div className="flex justify-between text-sm text-gray-600">
                        <span>{question.min_value}</span>
                        <span>{question.max_value}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <button
            onClick={handleSubmit}
            className="w-full py-3 px-6 bg-[#FF3008] text-white rounded-md mt-8 hover:bg-[#E62B07] transition-colors"
          >
            Enviar Respuestas
          </button>
        </div>
      </main>

      <footer className="bg-[#FF3008] text-white py-4 mt-8 text-center">
        <p className="text-sm">&copy; {new Date().getFullYear()} Hidden Shopper</p>
      </footer>
    </div>
  );
}

export default PublicSurvey;