// src/pages/Home.jsx

import React from "react";
import { motion } from "framer-motion";


function Home() {
  return (
    <div className="relative flex-grow flex flex-col">
      {/* Fondo con video */}
      <div className="absolute inset-0">
        <video
          autoPlay
          loop
          muted
          className="w-full h-full object-cover opacity-80"
        >
          <source src="/videos/homevideo.mp4" type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      </div>

      {/* Contenido principal */}
      <main className="relative z-10 flex-grow flex items-center justify-center px-4 py-8 sm:py-12">
        <div className="text-center text-white max-w-4xl">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-3xl sm:text-5xl font-bold tracking-wide mb-6"
          >
            Bienvenido al Sistema de Encuestas
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
            className="text-lg sm:text-xl"
          >
            Descubre la satisfacción de tus clientes en tiempo real y mejora su
            experiencia.
          </motion.p>

          {/* Tarjetas de ejemplo */}
          <motion.div
            className="mt-10 grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <div className="card bg-white shadow-lg rounded-lg p-6 text-center">
              <div className="w-12 h-12 bg-[#FF3008] text-white rounded-full flex items-center justify-center mx-auto mb-4 text-lg font-bold">
                1
              </div>
              <h3 className="text-base sm:text-lg font-semibold mb-2 text-gray-800">
                Registra tu Marca
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                Configura tu empresa y sucursales.
              </p>
            </div>

            <div className="card bg-white shadow-lg rounded-lg p-6 text-center">
              <div className="w-12 h-12 bg-[#FF3008] text-white rounded-full flex items-center justify-center mx-auto mb-4 text-lg font-bold">
                2
              </div>
              <h3 className="text-base sm:text-lg font-semibold mb-2 text-gray-800">
                Crea Encuestas
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                Personaliza tus preguntas.
              </p>
            </div>

            <div className="card bg-white shadow-lg rounded-lg p-6 text-center">
              <div className="w-12 h-12 bg-[#FF3008] text-white rounded-full flex items-center justify-center mx-auto mb-4 text-lg font-bold">
                3
              </div>
              <h3 className="text-base sm:text-lg font-semibold mb-2 text-gray-800">
                Analiza Resultados
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                Visualiza el feedback en tiempo real.
              </p>
            </div>
          </motion.div>
        </div>
      </main>
    </div>
  );
}

export default Home;
